import React from "react"
import ReactPlayer from "react-player"
import "./Video.css"

const Video = ({ url, thumbnail, className }) => {
  return (
    <section className={`video-player ${className}`}>
      <div className="player-wrapper">
        <ReactPlayer
          url={url}
          controls
          light={thumbnail}
          playing
          className="react-player"
          width="100%"
          height="100%"
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload'
              }
            }
          }}
        />
      </div>
    </section>
  )
}

export default Video
