import React from 'react'
import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Definition from "../Definition"
import './DetailedTableList.css'

import redFlower1 from "../../images/redFlower1.svg"
import redFlower2 from "../../images/redFlower2.svg"
import redFlower4 from "../../images/redFlower4.svg"

const DetailedTableList = ({ title, content }) => {
    const options = {
      renderNode: {
        [INLINES.EMBEDDED_ENTRY]: (node) => {
          return (
            <Definition
              word={node.data.target.definingWord}
              definition={node.data.target.definition}
            />
          )
        },
        [INLINES.ENTRY_HYPERLINK]: (node) => {
          return (
            <Definition
              word={node.content[0].value}
              definition={node.data.target.definition}
              entryLink
            >
              {node.content[0].value}
            </Definition>
          )
        },
      },
    }

  return (
    <div className="detailed-list-table">
      <div className="table-heading">
        <h3>{title}</h3>
        <div className="bouquet">
          <img className="red-flower" src={redFlower1} alt="" />
          <img className="red-flower" src={redFlower2} alt="" />
          <img className="red-flower" src={redFlower4} alt="" />
        </div>
      </div>
      {renderRichText(content, options)}
    </div>
  )
}

export default DetailedTableList
