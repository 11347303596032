import React from 'react'

import './SimpleTable.css'

const SimpleTable = ({ tableData }) => {
  const [headers] = tableData;
  return (
    <table className="simple-table">
      <thead>
        <tr>
          {headers.map((header) => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((row, index) => {
          if (index === 0) {
            return null;
          }
          return (
            <tr>
              {row.map((cell) => (
                <td>{cell}</td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default SimpleTable
