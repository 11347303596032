import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Definition from "../Definition"
import Video from "../Video"

import "./RichTextContent.css"
import SimpleTable from "../SimpleTable/SimpleTable"
import DetailedTableList from "../DetailedTableList/DetailedTableList"

const RichTextContent = ({ content }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="bold">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        if (node.data.target) {
          return (
            <GatsbyImage
              image={node.data.target.gatsbyImageData}
              alt={node.data.target.description}
              className="post-inline-image"
            />
          )
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.__typename === "ContentfulVideo") {
          return (
            <Video
              url={node.data.target.videoFile.file.url}
              thumbnail={node.data.target.thumbnail.file.url}
              className="post-page"
            />
          )
        }
        if (node.data.target.__typename === "ContentfulColorBlockedText") {
          return (
            <div className="color-block">
              <p>{renderRichText(node.data.target.content)}</p>
            </div>
          )
        }
        if (node.data.target.__typename === "ContentfulTable") {
          return <SimpleTable tableData={node.data.target.table.tableData} />
        }
        if (node.data.target.__typename === "ContentfulDetailedTable") {
          return (
            <DetailedTableList
              title={node.data.target.title}
              content={node.data.target.tableContents}
            />
          )
        }
        return null
      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        if (node.data.target.__typename === "ContentfulDefinition") {
          return (
            <Definition
              word={node.data.target.definingWord}
              definition={node.data.target.definition}
            />
          )
        }
        return null
      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        if (node.data.target.__typename === "ContentfulDefinition") {
          return (
            <Definition
              word={node.content[0].value}
              definition={node.data.target.definition}
              entryLink
            >
              {node.content[0].value}
            </Definition>
          )
        }
        return null
      }
    },
  }

  return (
    <article className="post-content">
      {renderRichText(content, options)}
    </article>
  )
}

export default RichTextContent
