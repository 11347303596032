import React, { useState, useEffect } from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import "./Definition.css"

const Definition = ({ word, definition, children, entryLink = false }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTransition, setModalTransition] = useState("")

  useEffect(() => {
    if (modalVisible) {
      setModalTransition("fade-in")
    } else {
      setModalTransition("fade-out")
    }
  }, [modalVisible])

  return (
    <>
      <button
        type="button"
        title={`Learn more about ${word}`}
        aria-label={`Learn more about ${word}`}
        onClick={() => setModalVisible(true)}
        className={`definition-button ${entryLink ? "entry-link" : ""} ${modalVisible ? 'modal-visible' : ''}`}
      >
        <span className="word">{children || word}</span>
      </button>
      {modalVisible && (
        <aside
          className={`definition-modal ${
            modalTransition ? modalTransition : ""
          }`}
          onAnimationEnd={() => {
            if (modalTransition === "fade-out") {
              setModalVisible(false)
            }
          }}
        >
          <div className="definition-wrapper">
            <button
              type="button"
              title="Close pop-up"
              aria-label="Close modal"
              onClick={() => setModalTransition("fade-out")}
              className="close-modal-button"
            >
              <span>&#726;</span>
            </button>
            {renderRichText(definition)}
          </div>
        </aside>
      )}
    </>
  )
}

export default Definition
